import {
    calculateRange,
    DatePicker,
    ExportCSV,
    IconButton,
    Icons,
    InfiniteTable as UIKitTable,
    Tooltip,
} from "@fm-frontend/uikit";
import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { EmDash, ValueFormat } from "@fm-frontend/utils";
import {
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { format, startOfDay, subMonths } from "date-fns";
import { FC, useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Gap from "~components/Gap";
import {
    PartnerSelect,
    PartnerSelectType,
    PartnerValueType,
    PARTNER_SELECT_ALL_VALUE,
} from "~components/PartnerSelect";
import { CounterpartyDropdownSelector } from "~components/Selectors/CounterpartyDropdown";
import { InstrumentDropdownSelector } from "~components/Selectors/InstrumentDropdown";
import DateTimeViewer from "~components/Table/Cell/DateTimeViewer";
import OptionsContainer from "~components/Table/Options/Container";
import Search from "~components/Table/Options/Search";
import { TablePaginator } from "~components/Table/Paginator";
import { TableContext, useTableContextValue } from "~components/Table/TableContext";
import { DATE_TIME_FORMAT } from "~constants/date";
import { DealHistoryItem } from "~entities/dealHistoryItem";
import { OrderSide } from "~entities/order";
import { dealHistoryFetcher, useDealHistoryApi } from "~hooks/api/useDealHistoryApi";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { useIsMaster } from "~hooks/useIsMaster";
import { useSubaccountsIds } from "~hooks/useSubaccountsIds";
import { TabList } from "~pages/History/TabList";
import { CounterpartyCell } from "~pages/History/Trades/CounterpartyCell";
import { DeltaCell } from "~pages/History/Trades/DeltaCell";
import InstrumentCell from "~pages/History/Trades/InstrumentCell";
import { OrderIdCell } from "~pages/History/Trades/OrderIdCell";
import { PriceCell } from "~pages/History/Trades/PriceCell";
import { SideCell } from "~pages/History/Trades/SideCell";
import { SizeCell } from "~pages/History/Trades/SizeCell";
import { TradeIdCell } from "~pages/History/Trades/TradeIdCell";
import { VolumeCell } from "~pages/History/Trades/VolumeCell";
import { getClient, getClients, isClientsFetched } from "~store/clients/selectors";
import { sortTimestamp } from "~utils/sortTimestamp";

const DEALS_LIMIT = 250;

export type Range = { startDate?: Date | null; endDate?: Date | null };

export const Table = styled(UIKitTable<DealHistoryItem>)`
    min-width: 1010px;

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
    }
`;

const columnHelper = createColumnHelper<DealHistoryItem>();

const dealsTableColumns = [
    columnHelper.accessor("orderId", {
        header: "Order ID",
        cell: (info) => <OrderIdCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "110px",
            },
        },
    }),
    columnHelper.accessor("counterpartyId", {
        id: "name",
        header: "Name",
        cell: (info) => <CounterpartyCell value={info.getValue()} />,
        // TODO: Find a way to type custom sorting functions
        // @ts-ignore
        sortingFn: "usernameSorting",
    }),
    columnHelper.accessor("instrumentName", {
        header: "Instrument",
        cell: (info) => <InstrumentCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "90px",
            },
        },
    }),
    columnHelper.accessor("side", {
        header: "Side",
        cell: (info) => <SideCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "50px",
            },
        },
    }),
    columnHelper.accessor("dealPrice", {
        header: "Price",
        cell: (info) => <PriceCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "150px",
            },
        },
    }),
    columnHelper.accessor("dealSize", {
        header: "Size",
        cell: (info) => <SizeCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "150px",
            },
        },
    }),
    columnHelper.accessor("dealVolume", {
        header: "Total volume",
        cell: (info) => <VolumeCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "150px",
            },
        },
    }),
    columnHelper.accessor("dealDelta", {
        header: "Delta",
        cell: (info) => <DeltaCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "150px",
            },
        },
    }),
    columnHelper.accessor("dealId", {
        header: "Trade ID",
        cell: (info) => <TradeIdCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "80px",
            },
        },
    }),
    columnHelper.accessor("dealMoment", {
        header: "Date",
        sortingFn: sortTimestamp,
        cell: (info) => <DateTimeViewer value={info.getValue() / 1000} />,
        meta: {
            headerStyleProps: {
                width: "100px",
            },
        },
    }),
];

const masterDealsTableColumns = [
    ...dealsTableColumns.slice(0, -1),
    columnHelper.accessor("linkedDealId", {
        header: "Linked Trade ID",
        cell: (info) => <TradeIdCell value={info.getValue()} />,
        meta: {
            headerStyleProps: {
                width: "80px",
            },
        },
    }),
    ...dealsTableColumns.slice(-1),
];

const isMatch = (historyItem: DealHistoryItem, counterpartyName: string, query: string) => {
    const { orderId, counterpartyId, instrumentName } = historyItem;
    const normalizedQuery = query.toUpperCase();

    return (
        String(orderId).startsWith(normalizedQuery) ||
        String(counterpartyId).startsWith(normalizedQuery) ||
        counterpartyName.toUpperCase().includes(normalizedQuery) ||
        instrumentName.toUpperCase().includes(normalizedQuery)
    );
};

const getDefaultRange = () => ({
    startDate: startOfDay(subMonths(new Date(), 1)),
    endDate: new Date(),
});

export const TradesHistory: FC = () => {
    const clientsFetched = useAppSelector(isClientsFetched);
    const clientId = useClientId();
    const isMaster = useIsMaster();
    const subaccountsIds = useSubaccountsIds(clientId);
    const [pages, setPages] = useState<number[]>([]);
    const [range, setRange] = useState<{ startDate?: Date | null; endDate?: Date | null }>(
        getDefaultRange(),
    );
    const [sorting, setSorting] = useState<SortingState>([]);
    const [partnerType, setPartnerType] = useState<PartnerValueType>(PARTNER_SELECT_ALL_VALUE);
    const tableContextValue = useTableContextValue();
    const { query, setQuery } = tableContextValue;
    const clients = useAppSelector(getClients);
    const getClientById = useAppSelector((state) => getClient(state));
    const counterpartyIds = clients.map((client) => client.id);

    const { data: instrumentsApiData } = useInstrumentsApi();
    const { instruments = [] } = instrumentsApiData ?? {};
    const instrumentNames = instruments.map((instrument) => instrument.name);

    const {
        control,
        watch,
        reset,
        formState: { isDirty },
    } = useForm({
        defaultValues: {
            selectedCounterparties: [] as number[],
            selectedInstruments: [] as string[],
        },
    });
    const selectedCounterparties = watch("selectedCounterparties");
    const selectedInstruments = watch("selectedInstruments");

    const { data = EMPTY_ARRAY, isLoading: isDealHistoryLoading } = useDealHistoryApi({
        clientId,
        limit: DEALS_LIMIT,
        counterpartyIds: selectedCounterparties,
        instrument: selectedInstruments,
        from: range.startDate?.getTime(),
        to: range.endDate?.getTime(),
        till: pages[0],
    });

    const isLoading = isDealHistoryLoading || !clientsFetched;

    const deals = useMemo(() => {
        if (query === "" && partnerType === PARTNER_SELECT_ALL_VALUE) {
            return data;
        }

        return data.filter((item) => {
            const isSubaccount = subaccountsIds.includes(item.counterpartyId);

            if (partnerType === PartnerSelectType.Counterparties && isSubaccount) {
                return false;
            }
            if (partnerType === PartnerSelectType.SubAccounts && !isSubaccount) {
                return false;
            }

            const { username = "" } = getClientById(item.counterpartyId) ?? {};

            return isMatch(item, username, query);
        });
    }, [query, partnerType, data, subaccountsIds, getClientById]);

    const hasPrevPage = pages.length > 0;
    const hasNextPage = deals.length === DEALS_LIMIT;

    const getExportData = async ({ startDate, endDate }: Range) => {
        let lastId;
        let exportData: Record<string, unknown>[] = [];

        // eslint-disable-next-line no-constant-condition
        while (true) {
            // Next iteration depends on the previous one
            // eslint-disable-next-line no-await-in-loop
            const dealHistory: DealHistoryItem[] = await dealHistoryFetcher({
                clientId,
                limit: DEALS_LIMIT,
                from: startDate?.getTime(),
                to: endDate?.getTime(),
                till: lastId,
            });
            const exportPageData = dealHistory.map((deal) => ({
                "Order ID": deal.orderId,
                "Client ID": deal.counterpartyId,
                Name: getClientById(deal.counterpartyId)?.username,
                Instrument: deal.instrumentName,
                Side: deal.side === OrderSide.Bid ? "Buy" : "Sell",
                Price: ValueFormat.price(deal.dealPrice),
                Size: ValueFormat.size(deal.dealSize),
                "Total volume": ValueFormat.size(deal.dealVolume),
                Delta: BigInt(deal.dealDelta) === 0n ? EmDash : ValueFormat.size(deal.dealDelta),
                "Trade ID": deal.dealId,
                ...(isMaster
                    ? { "Linked Trade ID": deal.linkedDealId === 0 ? EmDash : deal.linkedDealId }
                    : {}),
                Date: format(deal.orderCreatedAt, DATE_TIME_FORMAT),
            }));
            exportData = exportData.concat(exportPageData);

            if (dealHistory.length === 0) {
                break;
            }
            if (lastId !== dealHistory[dealHistory.length - 1].dealId) {
                lastId = dealHistory[dealHistory.length - 1].dealId;
            } else {
                break;
            }
        }

        return exportData;
    };

    const handleRangeChange = useCallback(({ startDate, endDate }: Range) => {
        setPages([]);
        setRange({ startDate, endDate });
    }, []);

    const handleRangeReset = useCallback(() => {
        setPages([]);
        setRange(getDefaultRange());
    }, []);

    const handlePrevClick = useCallback(() => {
        setPages(([, ...prevPages]) => prevPages);
    }, []);

    const handleNextClick = useCallback(() => {
        setPages((prevPages = []) => {
            const lastIndex = deals.length - 1;
            const lastId = deals[lastIndex].dealId;
            return lastId !== undefined ? [lastId, ...prevPages] : prevPages;
        });
    }, [deals]);

    const handleResetFilters = useCallback(() => {
        setPages([]);
        setRange(getDefaultRange());
        reset();
    }, [reset]);

    return (
        <TableContext.Provider value={tableContextValue}>
            <OptionsContainer>
                <Search query={query} onChange={setQuery} />
                <TabList />
                {isMaster && (
                    <PartnerSelect allTypes value={partnerType} onChange={setPartnerType} />
                )}
                <DatePicker
                    key={`${range.startDate}-${range.endDate}`}
                    startDate={range.startDate}
                    endDate={range.endDate}
                    onConfirm={handleRangeChange}
                    onReset={handleRangeReset}
                />
                <CounterpartyDropdownSelector
                    name="selectedCounterparties"
                    control={control}
                    counterparties={counterpartyIds}
                />
                <InstrumentDropdownSelector
                    name="selectedInstruments"
                    control={control}
                    instruments={instrumentNames}
                />
                {isDirty && (
                    <Tooltip content="Reset filter" align="center">
                        <IconButton
                            variant="plain"
                            type="button"
                            Icon={Icons.Recent}
                            onClick={handleResetFilters}
                        />
                    </Tooltip>
                )}
                <Gap />
                <ExportCSV
                    getData={getExportData}
                    defaultStartDate={startOfDay(subMonths(new Date(), 1))}
                    defaultEndDate={new Date()}
                    size="small"
                    defaultFileName={`trades_history_${clientId}`}
                />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data: deals,
                    columns: isMaster ? masterDealsTableColumns : dealsTableColumns,
                    state: {
                        sorting,
                    },
                    sortingFns: {
                        usernameSorting: (rowA, rowB, columnId) => {
                            const client1 = getClientById(rowA.getValue(columnId));
                            const client2 = getClientById(rowB.getValue(columnId));

                            if (
                                client1?.username === undefined ||
                                client2?.username === undefined
                            ) {
                                return 0;
                            }

                            return client1.username.localeCompare(client2.username);
                        },
                    },
                    onSortingChange: setSorting,
                    getCoreRowModel: getCoreRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                }}
                isLoading={isLoading}
            />
            <TablePaginator
                range={calculateRange(deals, "dealMoment")}
                pageItemsCount={deals.length}
                allItemsCount={DEALS_LIMIT * pages.length + deals.length}
                isLoading={isLoading}
                hasPrevPage={hasPrevPage}
                hasNextPage={hasNextPage}
                onPrevClick={handlePrevClick}
                onNextClick={handleNextClick}
            />
        </TableContext.Provider>
    );
};
