import { ClientType } from "~entities/client";
import { ClientInfo } from "~entities/clientInfo";

export const isRfqAvailable = (clientType: ClientType | undefined) =>
    clientType === ClientType.SubaccountTaker ||
    clientType === ClientType.Taker ||
    clientType === ClientType.PrimeBroker;

export const isSigned = (clientInfo: ClientInfo | undefined) =>
    clientInfo?.signatory !== "" &&
    clientInfo?.signatoryPosition !== "" &&
    clientInfo?.signedAt !== 0 &&
    clientInfo?.agreementNr !== "";
