import { TabContext, TabPanel } from "@fm-frontend/uikit";
import { InfiniteTableContainer } from "@fm-frontend/uikit/src/components/table/InfiniteTable/Container";
import { FC, useState } from "react";
import styled from "styled-components";
import { SettlementsHistory } from "~pages/History/Settlements";
import { Tabs } from "~pages/History/TabList";
import { OvernightsHistory } from "~pages/History/Technical";
import { TradesHistory } from "~pages/History/Trades";

const HistoryTabContext = styled(TabContext)`
    flex: 1;
`;

const HistoryTabPanel = styled(TabPanel)`
    flex: 1;

    ${InfiniteTableContainer} {
        flex: 1;
    }
`;

export const HistoryPage: FC = () => {
    const [tab, setTab] = useState<string>(Tabs.Trades);

    return (
        <HistoryTabContext value={tab} handleClick={setTab}>
            <HistoryTabPanel value={Tabs.Trades} overflow>
                <TradesHistory />
            </HistoryTabPanel>
            <HistoryTabPanel value={Tabs.Settlements} overflow>
                <SettlementsHistory />
            </HistoryTabPanel>
            <HistoryTabPanel value={Tabs.Technical} overflow>
                <OvernightsHistory />
            </HistoryTabPanel>
        </HistoryTabContext>
    );
};
