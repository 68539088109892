import { ClientId } from "~entities/client";

export enum OrderType {
    Limit,
    PostOnly,
    LimitIOC,
    LimitFOK,
    MarketIOC,
    MarketFOK,
    ManualTrade,
    LiquidationTrade,
}

export enum OrderSide {
    Bid,
    Ask,
}

export enum OrderCancelReason {
    InPlaceOrFilled,
    ByClient,
    AsNonBookOrder,
    BySelfTradePrevention,
    CancelOnDisconnect,
}

export enum OrderCreatedByType {
    BySize,
    ByVolume,
}

export type OrderId = number;

export type Order = {
    instrumentName: string;
    orderType: OrderType;
    side: OrderSide;
    cancelReason: OrderCancelReason;
    orderId: OrderId;
    orderClientId: ClientId;
    orderPrice: number | bigint;
    orderInitialSizeOrVolume: number | bigint;
    orderRemainingSizeOrVolume: number | bigint;
    orderCreatedAt: number;
    createdByType: OrderCreatedByType;
};
