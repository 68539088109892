import { BasicButton } from "@fm-frontend/uikit";
import { FC } from "react";
import ModalButton from "~components/ModalButton";
import { Client } from "~entities/client";
import { ClientInfo } from "~entities/clientInfo";
import ModifyForm from "~pages/Profile/ModifyForm";

type ModifyButtonProps = {
    client: Client | undefined;
    clientInfo: ClientInfo | undefined;
    allTags: string[] | undefined;
    hrpId: string | undefined;
};

const ModifyButton: FC<ModifyButtonProps> = ({ client, clientInfo, allTags, hrpId }) => {
    const disabled = client === undefined || clientInfo === undefined;

    return (
        <ModalButton
            renderModalContent={(closePopover) =>
                !disabled && (
                    <ModifyForm
                        client={client}
                        clientInfo={clientInfo}
                        allTags={allTags}
                        hrpId={hrpId}
                        onDone={closePopover}
                    />
                )
            }
            renderButton={(handleClick) => (
                <BasicButton size="small" disabled={disabled} onClick={handleClick}>
                    Modify
                </BasicButton>
            )}
        />
    );
};

export default ModifyButton;
