import { ExportData } from "@fm-frontend/utils";
import { FC, useContext } from "react";
import Gap from "~components/Gap";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import Title from "~components/Table/Options/Title";
import useAppSelector from "~hooks/useAppSelector";
import AnalyticsButton from "~pages/Profile/AnalyticsButton";
import { ProfileContext } from "~pages/Profile/Context";
import ModifyButton from "~pages/Profile/ModifyButton";
import { ClientStatus } from "~pages/Profile/Options/ClientStatus";
import { getClientsTags } from "~store/clients/selectors";

type OptionsProps = {
    exportData: ExportData[] | (() => ExportData[]);
};

const Options: FC<OptionsProps> = ({ exportData }) => {
    const { client, clientInfo, hrpId } = useContext(ProfileContext);
    const clientsTags = useAppSelector(getClientsTags);

    return (
        <OptionsContainer>
            <Title>
                {client?.username} <ClientStatus disabled={client?.isDisabled} />
            </Title>
            <Gap />
            <AnalyticsButton clientId={client?.id} />
            <ModifyButton
                client={client}
                clientInfo={clientInfo}
                allTags={clientsTags}
                hrpId={hrpId}
            />
            <ExportButton filename={`profile_${client?.id}`} data={exportData} />
        </OptionsContainer>
    );
};

export default Options;
