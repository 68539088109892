import { InfiniteTable as UIKitTable } from "@fm-frontend/uikit";
import { formatPercent, ValueFormat } from "@fm-frontend/utils";
import { ExpandedState, getCoreRowModel, getExpandedRowModel } from "@tanstack/react-table";
import { ReactNode, useMemo, useState } from "react";
import styled from "styled-components";
import OptionsContainer from "~components/Table/Options/Container";
import ExportButton from "~components/Table/Options/ExportButton";
import { useClientsApi } from "~hooks/api/useClientsApi";
import { useInstrumentsApi } from "~hooks/api/useInstrumentsApi";
import useAppSelector from "~hooks/useAppSelector";
import { useClientId } from "~hooks/useClientId";
import { useRiskManagementAssets } from "~hooks/useRiskManagementAssets";
import { EditAssetLimitAction } from "~pages/RisksManagement/components/EditAssetLimitAction";
import { NegativeValue } from "~pages/RisksManagement/components/NegativeValue";
import {
    getClientColumn,
    getCounterpartyColumn,
    getExpandColumn,
    getExtraColumn,
    getTitleColumn,
} from "~pages/RisksManagement/View/AssetsControl/columns";
import { StatusBadge } from "~pages/RisksManagement/View/AssetsControl/StatusBadge";
import { Item, RowType, SubItem } from "~pages/RisksManagement/View/AssetsControl/types";
import {
    getAssetStatus,
    getExposureAbs,
    getGrossFree,
} from "~pages/RisksManagement/View/AssetsControl/utils";
import { BackButton } from "~pages/RisksManagement/View/components/BackButton";
import { HeaderCell } from "~pages/RisksManagement/View/components/HeaderCell";
import { useCounterpartyId } from "~pages/RisksManagement/View/hooks/useCounterpartyId";
import { useExposures } from "~pages/RisksManagement/View/hooks/useExposures";
import { TabList } from "~pages/RisksManagement/View/TabList";
import { getClient, isClientsFetching } from "~store/clients/selectors";

const getInfoRow = (
    title = "",
    clientValue: ReactNode = null,
    counterpartyValue: ReactNode = null,
) => ({
    type: RowType.Info,
    title,
    clientValue,
    counterpartyValue,
});

const getSpaceRow = () => getInfoRow();

const Table = styled(UIKitTable<Item | SubItem>)`
    min-width: 1010px;
    width: unset;

    thead {
        th {
            //due to transparency nature of UIkit colors
            background-color: #606a6d;
            height: 32px;
            padding-right: 12px;
            padding-bottom: unset;

            span {
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.44px;
                text-transform: uppercase;
                color: ${(p) => p.theme.colors.uiWhite100};
            }
        }
    }

    td {
        border-right: 1px solid ${(p) => p.theme.colors.ui8};
        padding-right: 12px;
    }

    th:first-of-type,
    td:first-of-type {
        padding-left: 12px !important;
        border-right: unset;
    }
`;

export const RisksManagementViewAssetsControl = () => {
    useClientsApi();

    const isClientsLoading = useAppSelector(isClientsFetching);
    const getClientById = useAppSelector(getClient);
    const clientId = useClientId();
    const counterpartyId = useCounterpartyId();
    const client = getClientById(clientId);
    const counterparty = getClientById(counterpartyId);
    const clientTitle = `${client?.username} (${clientId})`;
    const counterpartyTitle = `${counterparty?.username} (${counterpartyId})`;

    const [expanded, setExpanded] = useState<ExpandedState>({});
    const { data: assetsData, isLoading: isAssetsLoading } = useRiskManagementAssets(
        clientId,
        counterpartyId,
    );
    const { clientAssetLimits, counterpartyAssetLimits, overnightFees, shortSalesBan } =
        assetsData ?? {};
    const { data: instrumentsData, isLoading: isInstrumentsLoading } = useInstrumentsApi();
    const { currencies = [] } = instrumentsData ?? {};
    const { data: clientExposures, isLoading: isClientExposuresLoading } = useExposures(
        clientId,
        counterpartyId,
    );
    const { data: counterpartyExposures, isLoading: isCounterpartyExposuresLoading } = useExposures(
        counterpartyId,
        clientId,
    );

    const isLoading =
        isAssetsLoading ||
        isInstrumentsLoading ||
        isClientsLoading ||
        isClientExposuresLoading ||
        isCounterpartyExposuresLoading;

    const data: Item[] = currencies
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name }) => ({
            type: RowType.Currency,
            title: name,
            clientValue: (
                <StatusBadge limit={clientAssetLimits?.[name]} exposure={clientExposures[name]} />
            ),
            counterpartyValue: (
                <StatusBadge
                    limit={counterpartyAssetLimits?.[name]}
                    exposure={counterpartyExposures[name]}
                />
            ),
            items: [
                getInfoRow(
                    "Limit currency",
                    clientAssetLimits?.[name]?.limitCurrency ?? name,
                    counterpartyAssetLimits?.[name]?.limitCurrency ?? name,
                ),
                getInfoRow(
                    "Limit per asset",
                    ValueFormat.size(clientAssetLimits?.[name]?.grossLimit ?? 0),
                    ValueFormat.size(counterpartyAssetLimits?.[name]?.grossLimit ?? 0),
                ),
                getInfoRow(
                    "Free",
                    getGrossFree(clientAssetLimits?.[name]?.grossLimit, clientExposures[name]),
                    getGrossFree(
                        counterpartyAssetLimits?.[name]?.grossLimit,
                        counterpartyExposures[name],
                    ),
                ),
                getInfoRow(
                    "Exposure",
                    ValueFormat.size(getExposureAbs(clientExposures[name])),
                    ValueFormat.size(getExposureAbs(counterpartyExposures[name])),
                ),
                getSpaceRow(),
                getInfoRow(
                    "Overnight Fee Long",
                    formatPercent(overnightFees?.[name]?.positiveRate ?? 0),
                ),
                getInfoRow(
                    "Overnight Fee Short",
                    formatPercent(overnightFees?.[name]?.negativeRate ?? 0),
                ),
                getSpaceRow(),
                getInfoRow(
                    "Short sale",
                    null,
                    shortSalesBan?.[name] === undefined ? (
                        "Allowed"
                    ) : (
                        <NegativeValue>Ban</NegativeValue>
                    ),
                ),
            ],
        }));

    const columns = useMemo(
        () => [
            getExpandColumn(),
            getTitleColumn(),
            getClientColumn(clientTitle),
            getCounterpartyColumn(counterpartyTitle),
            getExtraColumn(),
        ],
        [clientTitle, counterpartyTitle],
    );

    const getExportRow = (title = "", clientValue = "", counterpartyValue = "") => ({
        title,
        [clientTitle]: clientValue,
        [counterpartyTitle]: counterpartyValue,
    });

    const getExportData = () =>
        currencies
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ name }) => [
                getExportRow(
                    name,
                    getAssetStatus(clientAssetLimits?.[name], clientExposures[name]),
                    getAssetStatus(counterpartyAssetLimits?.[name], counterpartyExposures[name]),
                ),
                getExportRow(
                    "Limit currency",
                    clientAssetLimits?.[name]?.limitCurrency ?? name,
                    counterpartyAssetLimits?.[name]?.limitCurrency ?? name,
                ),
                getExportRow(
                    "Limit per asset",
                    ValueFormat.size(clientAssetLimits?.[name]?.grossLimit ?? 0),
                    ValueFormat.size(counterpartyAssetLimits?.[name]?.grossLimit ?? 0),
                ),
                getExportRow(
                    "Free",
                    getGrossFree(clientAssetLimits?.[name]?.grossLimit, clientExposures[name]),
                    getGrossFree(
                        counterpartyAssetLimits?.[name]?.grossLimit,
                        counterpartyExposures[name],
                    ),
                ),
                getExportRow(
                    "Exposure",
                    ValueFormat.size(getExposureAbs(clientExposures[name])),
                    ValueFormat.size(getExposureAbs(counterpartyExposures[name])),
                ),
                getExportRow(
                    "Overnight Fee Long",
                    formatPercent(overnightFees?.[name]?.positiveRate ?? 0),
                ),
                getExportRow(
                    "Overnight Fee Short",
                    formatPercent(overnightFees?.[name]?.negativeRate ?? 0),
                ),
                getExportRow(
                    "Short sale",
                    "",
                    shortSalesBan?.[name] === undefined ? "Allowed" : "Ban",
                ),
                getExportRow(),
            ])
            .flat();

    return (
        <>
            <OptionsContainer>
                <HeaderCell>
                    <BackButton />
                </HeaderCell>

                <TabList />
                <EditAssetLimitAction />
                <ExportButton
                    data={getExportData}
                    filename={`risks_management_assets_${clientId}-${counterpartyId}`}
                    loading={isLoading}
                />
            </OptionsContainer>
            <Table
                tableOptions={{
                    data,
                    columns,
                    state: {
                        expanded,
                    },
                    onExpandedChange: setExpanded,
                    getCoreRowModel: getCoreRowModel(),
                    getExpandedRowModel: getExpandedRowModel(),
                    getSubRows: (row) => {
                        return row.items;
                    },
                    getRowCanExpand: (row) => {
                        return row.original.type !== RowType.Info;
                    },
                }}
                onRowClick={(row) => {
                    const isExpandable = row.getCanExpand();

                    if (isExpandable) {
                        row.toggleExpanded();
                    }
                }}
                isLoading={isLoading}
            />
        </>
    );
};
