import { forwardRef, ReactNode, useEffect, useState } from "react";
import styled, { DefaultTheme, StyledComponent } from "styled-components";
import { useWidth } from "../../../hooks";
import { HintVariant, HintVariantComponents } from "../../common/HintVariantComponents";
import { getClickHelper } from "../../helper";
import { Icons } from "../../icons";
import { TextSmall } from "../../typography";
import { InputProps, InputVariant, TradeAmountInputProps } from "./Input.types";
import {
    BasicInput,
    BasicInput as MinimumInput,
    BasicLabel,
    Container,
    InputGroup,
    SimpleInput,
    SimpleLabel,
} from "./styled";

const InputVariantComponents: {
    [Key in InputVariant]: StyledComponent<"input", DefaultTheme, { isError?: boolean }, never>;
} = {
    basic: BasicInput,
    simple: SimpleInput,
    minimum: MinimumInput,
};

const LabelVariantComponents: {
    [Key in InputVariant]: StyledComponent<"label", DefaultTheme, { offset: number }, never>;
} = {
    basic: BasicLabel,
    simple: SimpleLabel,
    minimum: styled.label`
        display: none;
    `,
};

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const {
        variant,
        label,
        labelHelper,
        labelHelperOptions,
        name,
        hint,
        error,
        disabled,
        _size = "large",
        showError = true,
    } = props;

    const [hintVariant, setHintVariant] = useState<{
        variant: HintVariant;
        text?: string | ReactNode;
    }>({
        variant: "hint",
    });

    useEffect(() => {
        if (error) {
            setHintVariant({ variant: "error", text: error });
        } else {
            setHintVariant({ variant: "hint", text: hint });
        }
    }, [error, hint]);

    const InputComponent = InputVariantComponents[variant];
    const HintComponent = HintVariantComponents[hintVariant.variant];
    const LabelComponent = LabelVariantComponents[variant];

    const { offset, ref: labelRef } = useWidth({ label });

    return (
        <Container size={_size} isDisabled={disabled}>
            <InputGroup>
                {label && (
                    <LabelComponent offset={offset} ref={labelRef} htmlFor={name}>
                        {label}
                        {getClickHelper(labelHelper, labelHelperOptions)}
                    </LabelComponent>
                )}
                <InputComponent
                    data-field-group
                    ref={ref}
                    isError={hintVariant.variant === "error"}
                    {...props}
                />
            </InputGroup>
            {hintVariant.text && showError && (
                <HintComponent>
                    <TextSmall>{hintVariant.text}</TextSmall>
                </HintComponent>
            )}
        </Container>
    );
});

const SwapButton = styled.button`
    all: reset;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    cursor: pointer;
    background: transparent;

    > div {
        text-align: left;
    }

    :hover svg path {
        fill-opacity: 0.8;
    }

    :focus-visible {
        box-shadow: 0px 0px 0px 3px ${(p) => p.theme.colors.brand12},
        inset 0px 0px 0px 1px ${(p) => p.theme.colors.brand72};
`;
const AssetLabel = styled.div<{ $selected: boolean }>`
    font-size: 8px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: -0.04px;
    color: ${(p) => (p.$selected ? p.theme.colors.ui100 : p.theme.colors.ui32)};
`;
const InnerSwapSwitch = ({
    value,
    onChange,
    assetCurrency,
    balanceCurrency,
}: {
    value: string;
    onChange: (value: string) => void;
    assetCurrency: string;
    balanceCurrency: string;
}) => {
    return (
        <SwapButton
            type="button"
            onClick={() => onChange(value === assetCurrency ? balanceCurrency : assetCurrency)}
        >
            <Icons.VerticalSwap />
            <div>
                <AssetLabel $selected={value === assetCurrency}>{assetCurrency}</AssetLabel>
                <AssetLabel $selected={value === balanceCurrency}>{balanceCurrency}</AssetLabel>
            </div>
        </SwapButton>
    );
};
const TradeAmountLabel = styled(SimpleLabel)`
    left: 8px;
`;
export const TradeAmountInput = forwardRef<HTMLInputElement, TradeAmountInputProps>(
    (props, ref) => {
        const {
            name,
            hint,
            error,
            disabled,
            assetCurrency,
            balanceCurrency,
            selectedTradeCurrency,
            onTradeCurrencyChange,
            showError = true,
        } = props;

        const [hintVariant, setHintVariant] = useState<{
            variant: HintVariant;
            text?: string | ReactNode;
        }>({
            variant: "hint",
        });

        useEffect(() => {
            if (error) {
                setHintVariant({ variant: "error", text: error });
            } else {
                setHintVariant({ variant: "hint", text: hint });
            }
        }, [error, hint]);

        const HintComponent = HintVariantComponents[hintVariant.variant];

        return (
            <Container size="medium" isDisabled={disabled}>
                <InputGroup>
                    <TradeAmountLabel offset={0} htmlFor={name}>
                        <InnerSwapSwitch
                            value={selectedTradeCurrency}
                            onChange={onTradeCurrencyChange}
                            assetCurrency={assetCurrency}
                            balanceCurrency={balanceCurrency}
                        />
                    </TradeAmountLabel>
                    <SimpleInput
                        data-field-group
                        ref={ref}
                        isError={hintVariant.variant === "error"}
                        {...props}
                    />
                </InputGroup>
                {hintVariant.text && showError && (
                    <HintComponent>
                        <TextSmall>{hintVariant.text}</TextSmall>
                    </HintComponent>
                )}
            </Container>
        );
    },
);
