import { noop } from "@fm-frontend/uikit/src/const";
import { createContext, Dispatch, SetStateAction, useMemo, useState } from "react";
import { Client } from "~entities/client";
import { ClientInfo } from "~entities/clientInfo";

type ProfileContextValue = {
    client: Client | undefined;
    setClient: Dispatch<SetStateAction<Client | undefined>>;
    clientInfo: ClientInfo | undefined;
    setClientInfo: Dispatch<SetStateAction<ClientInfo | undefined>>;
    hrpId: string | undefined;
    setHRPId: Dispatch<SetStateAction<string | undefined>>;
};

export const ProfileContext = createContext<ProfileContextValue>({
    client: undefined,
    setClient: noop,
    clientInfo: undefined,
    setClientInfo: noop,
    hrpId: undefined,
    setHRPId: noop,
});

export const useProfileContextValue = () => {
    const [client, setClient] = useState<Client>();
    const [clientInfo, setClientInfo] = useState<ClientInfo>();
    const [hrpId, setHRPId] = useState<string>();

    return useMemo(
        () => ({
            client,
            setClient,
            clientInfo,
            setClientInfo,
            hrpId,
            setHRPId,
        }),
        [client, clientInfo, hrpId],
    );
};
