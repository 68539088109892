import { Tab, TabList as UIKitTabList } from "@fm-frontend/uikit";
import styled from "styled-components";

export enum Tabs {
    Trades = "Trades",
    Settlements = "Settlements",
    Technical = "Technical",
}

const Container = styled.div`
    width: 330px;
`;

export const TabList = () => {
    return (
        <Container>
            <UIKitTabList size="small">
                <Tab title="Trades" value={Tabs.Trades} />
                <Tab title="Settlements" value={Tabs.Settlements} />
                <Tab title="Technical" value={Tabs.Technical} />
            </UIKitTabList>
        </Container>
    );
};
