import {
    apiUrl,
    hrpReportingApiUrl,
    onboardingApiUrl,
    rfqApiUrl,
    settingsApiUrl,
} from "~constants/api";
import { ClientId, SubsFmRelationType } from "~entities/client";
import { CurrencyType } from "~entities/currency";
import { CpLimitFilterType } from "~hooks/api/useCounterpartyLimitsApi";
import { store } from "~store";
import BigIntJSON from "~utils/BigIntJSON";
import { getNonce } from "~utils/getNonce";
import { getToken } from "~utils/getToken";

export enum Api {
    adminery,
    onboarding,
    settings,
    hrpReporting,
    rfq,
}

const getBaseUrl = (api: Api) => {
    switch (api) {
        case Api.onboarding:
            return onboardingApiUrl;
        case Api.settings:
            return settingsApiUrl;
        case Api.hrpReporting:
            return hrpReportingApiUrl;
        case Api.rfq:
            return rfqApiUrl;
        default:
            return apiUrl;
    }
};

export const request = async (method: string, data: object, api: Api = Api.adminery) => {
    const { token, isAuthed } = await getToken();
    const body = BigIntJSON.stringify({
        ...data,
        ...(isAuthed && {
            nonce: getNonce(),
            timestamp: Date.now(),
        }),
    });

    const response = await fetch(`${getBaseUrl(api)}/${method}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "EFX-Key": token,
            "EFX-Sign": "",
        },
        body,
    });
    const responseText = await response.text();

    if (response.ok) {
        return BigIntJSON.parse(responseText);
    }

    const { error } = BigIntJSON.parse(responseText);

    store.dispatch({ type: "setError", payload: { error } });
    throw new Error(error);
};

export type ReturnTypeOfRequest = ReturnType<typeof request>;

type AssetLimitsParams = {
    clientId: ClientId;
};
type ClientInfoProps = {
    id: ClientId;
};
type ResetMFAParams = {
    email: string;
};
type DelLimitParams = {
    clientId: ClientId;
};
type SetLimitParams = {
    clientId: ClientId;
    currency: string;
    grossLimit: bigint;
};
type SetInstrumentsWhitelistParams = {
    clientId: ClientId;
    instruments: string[];
};
type DisableInstrumentsWhitelistParams = {
    clientId: ClientId;
};
type EnableInstrumentsWhitelistParams = {
    clientId: ClientId;
};
type DelCLimitParams = {
    clientId: ClientId;
    counterpartyId: number;
};
type SetCLimitParams = {
    clientId: ClientId;
    counterpartyId: number;
    currency: string;
    grossLimit: bigint;
    maintenanceMargin?: number;
    restrictedTrading?: number;
    initialMargin?: number;
    takerMarkup?: number;
};
type DelCAssetLimitParams = {
    clientId: ClientId;
    counterpartyId: number;
    asset: string;
};
type SetCAssetLimitParams = {
    clientId: ClientId;
    counterpartyId: number;
    asset: string;
    grossLimit: bigint;
    limitCurrency: string;
};
type DisableClientParams = {
    id: ClientId;
};
type EnableClientParams = {
    id: ClientId;
};
type SetClientMetadataParams = {
    clientId: ClientId;
    tagName: "tags";
    tagContent: string[];
};
type ModClientParams = {
    id: ClientId;
    name: string;
    fullName: string;
    email: string;
};
type UpdateClientParams = {
    id: ClientId;
    makerFee: number;
    fineryShare: number;
};
type SendInviteLinkParams = {
    name: string;
    email: string;
    type: string;
    takerMarkup: number;
    makerFee?: number;
    fineryShare?: number;
    subsFmRelationType?: SubsFmRelationType;
};
type AddKeyParams = {
    tag: string;
    allowWrite: boolean;
};
type DelKeyParams = {
    key: string;
};
type ExpSettlementOrderParams = {
    settlementOrderId: number;
};
type DelSettlementOrderParams = {
    settlementOrderId: number;
};
export type AddSettlementOrderParams = {
    clientIdA: ClientId;
    clientIdB: ClientId;
    currency1: string;
    size1: bigint;
    comment: string;
    network?: string;
};
type DelNetworkParams = {
    name: string;
};
type ModNetworkParams = {
    name: string;
    description: string;
};
type AssignNetworksParams = {
    currency: string;
    networks: string[];
};
type AddNetworkParams = {
    name: string;
    description: string;
};
type DelInstrumentParams = {
    name: string;
};
type AddInstrumentParams = {
    name: string;
};
type SetCurrencyTypeParams = {
    name: string;
    type: CurrencyType;
};
type SetCurrencyPriceParams = {
    name: string;
    price: bigint;
};
type DelCurrencyParams = {
    name: string;
};
type AddCurrencyParams = {
    name: string;
    type: CurrencyType;
    balanceStep: bigint;
    price: bigint;
};
type GetClientsParams = {
    activityDays: number;
};
type CLimitsParams = {
    clientId: ClientId;
    filter?: CpLimitFilterType;
};
type DealHistoryParams = {
    clientId: ClientId;
};
type ExternalLimitParams = {
    clientId: ClientId;
};
type LimitsParams = {
    clientId: ClientId;
};
type InstrumentsParams = {
    [key: string]: never;
};
type InstrumentsWhitelistParams = {
    clientId: ClientId;
};
type InternalLimitParams = {
    clientId: ClientId;
};
type KeysParams = {
    [key: string]: never;
};
type PositionsParams = {
    clientId: ClientId;
};
type GetInvitesListParams = {
    // clientId = undefined returns all admins invites
    clientId?: ClientId;
};
type SettlementOrdersParams = {
    clientId: ClientId;
};
type SettlementRequestsParams = {
    clientId: ClientId;
};
type SettlementTransactionHistoryParams = {
    clientId: ClientId;
};
type SettlementHistoryParams = {
    clientId: ClientId;
    filterTransactions: boolean;
};
type SettlementTransactionsParams = {
    clientId: ClientId;
};
type ShortSalesBanParams = {
    clientId: ClientId;
};
type GetSubaccountsParams = {
    clientId: ClientId;
};
type GetUsersParams = {
    clientId: ClientId;
};
type CancelInviteLinkParams = {
    email: string;
};
type InstrumentMarkupsProps = {
    clientId: ClientId;
};
type AddInstrumentMarkupsProps = {
    clientId: number;
    counterpartyId: number;
    instrument: string;
    bidMarkup?: number;
    askMarkup?: number;
};
type DelInstrumentMarkupsProps = {
    clientId: number;
    counterpartyId: number;
    instrument: string;
    bidMarkup?: number;
    askMarkup?: number;
};
type MigrateUserParams = {
    email: string;
};
type ResetLegalDataParams = {
    clientId: ClientId;
};

export async function requestAdminery(
    method: "cancelInviteLink",
    data: CancelInviteLinkParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "getUsers",
    data: GetUsersParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "getSubaccounts",
    data: GetSubaccountsParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "cShortSalesBan",
    data: ShortSalesBanParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "settlementTransactions",
    data: SettlementTransactionsParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "settlementHistory",
    data: SettlementHistoryParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "settlementTransactionHistory",
    data: SettlementTransactionHistoryParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "settlementRequests",
    data: SettlementRequestsParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "settlementOrders",
    data: SettlementOrdersParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "getInvitesList",
    data: GetInvitesListParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "positions",
    data: PositionsParams,
): ReturnTypeOfRequest;
export async function requestAdminery(method: "keys", data: KeysParams): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "internalLimit",
    data: InternalLimitParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "instrumentsWhitelist",
    data: InstrumentsWhitelistParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "instruments",
    data: InstrumentsParams,
): ReturnTypeOfRequest;
export async function requestAdminery(method: "limits", data: LimitsParams): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "externalLimit",
    data: ExternalLimitParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "dealHistory",
    data: DealHistoryParams,
): ReturnTypeOfRequest;
export async function requestAdminery(method: "climits", data: CLimitsParams): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "getClients",
    data: GetClientsParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "addCurrency",
    data: AddCurrencyParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "delCurrency",
    data: DelCurrencyParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "setCurrencyPrice",
    data: SetCurrencyPriceParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "setCurrencyType",
    data: SetCurrencyTypeParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "addInstrument",
    data: AddInstrumentParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "delInstrument",
    data: DelInstrumentParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "addNetwork",
    data: AddNetworkParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "assignNetworks",
    data: AssignNetworksParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "modNetwork",
    data: ModNetworkParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "delNetwork",
    data: DelNetworkParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "addSettlementOrder",
    data: AddSettlementOrderParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "delSettlementOrder",
    data: DelSettlementOrderParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "expSettlementOrder",
    data: ExpSettlementOrderParams,
): ReturnTypeOfRequest;
export async function requestAdminery(method: "delKey", data: DelKeyParams): ReturnTypeOfRequest;
export async function requestAdminery(method: "addKey", data: AddKeyParams): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "sendInviteLink",
    data: SendInviteLinkParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "updateClient",
    data: UpdateClientParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "modClient",
    data: ModClientParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "setClientMetadata",
    data: SetClientMetadataParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "enableClient",
    data: EnableClientParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "disableClient",
    data: DisableClientParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "setCAssetLimit",
    data: SetCAssetLimitParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "delCAssetLimit",
    data: DelCAssetLimitParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "setCLimit",
    data: SetCLimitParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "delCLimit",
    data: DelCLimitParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "enableInstrumentsWhitelist",
    data: EnableInstrumentsWhitelistParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "disableInstrumentsWhitelist",
    data: DisableInstrumentsWhitelistParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "setInstrumentsWhitelist",
    data: SetInstrumentsWhitelistParams,
): ReturnTypeOfRequest;
// Set global gross limit
export async function requestAdminery(
    method: "setLimit" | "setInternalLimit" | "setExternalLimit",
    data: SetLimitParams,
): ReturnTypeOfRequest;
// Delete global gross limit
export async function requestAdminery(
    method: "delLimit" | "delInternalLimit" | "delExternalLimit",
    data: DelLimitParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "resetMFA",
    data: ResetMFAParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "cAssetLimits",
    data: AssetLimitsParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "instrumentMarkups",
    data: InstrumentMarkupsProps,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "addInstrumentMarkups",
    data: AddInstrumentMarkupsProps,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "delInstrumentMarkups",
    data: DelInstrumentMarkupsProps,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "clientInfo",
    data: ClientInfoProps,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "migrateUser",
    data: MigrateUserParams,
): ReturnTypeOfRequest;
export async function requestAdminery(
    method: "resetLegalData",
    data: ResetLegalDataParams,
): ReturnTypeOfRequest;
export async function requestAdminery(method: string, data: object) {
    return request(method, data, Api.adminery);
}

// RFQ API

type RfqGetSettingsParams = {
    clientId: ClientId;
};
type RfqSetSettingsParams = {
    clientId: ClientId;
    tradingEnabled: boolean;
    viewingEnabled: boolean;
};
export async function requestRfq(
    method: "getSettings",
    data: RfqGetSettingsParams,
): ReturnTypeOfRequest;
export async function requestRfq(
    method: "setSettings",
    data: RfqSetSettingsParams,
): ReturnTypeOfRequest;
export async function requestRfq(method: string, data: object) {
    return request(method, data, Api.rfq);
}

// Onboarding APIs

type MakerInfoParams = {
    [key: string]: never;
};
type TakerInfoParams = {
    clientId: string;
};
type InviteStatusParams = {
    clientId: ClientId[];
};
export async function requestOnboarding(
    method: "makerInfo",
    data: MakerInfoParams,
): ReturnTypeOfRequest;
export async function requestOnboarding(
    method: "takerInfo",
    data: TakerInfoParams,
): ReturnTypeOfRequest;
export async function requestOnboarding(
    method: "inviteStatus",
    data: InviteStatusParams,
): ReturnTypeOfRequest;
export async function requestOnboarding(method: string, data: object) {
    return request(method, data, Api.onboarding);
}

// Settings APIs

type GetOvernightRatesProps = {
    clientId: ClientId;
};
export async function requestSettings(
    method: "getOvernightRates",
    data: GetOvernightRatesProps,
): ReturnTypeOfRequest;
export async function requestSettings(method: string, data: object): ReturnTypeOfRequest {
    return request(method, data, Api.settings);
}

// HRP reporting APIs

type GetClientsProps = {
    [key: string]: never;
};
type GetInstrumentsProps = {
    [key: string]: never;
};
type HrpMarketsProps = {
    [key: string]: never;
};
type MapInstrumentProps = {
    fmInstrument: string;
    hrpInstrument: string;
};
type DelInstrumentProps = {
    fmInstrument: string;
};
type MapClientProps = {
    fmClientId: ClientId;
    hrpClientId: string;
};
type DelClientProps = {
    fmClientId: ClientId;
};
export async function requestHrpReporting(
    method: "getClients",
    data: GetClientsProps,
): ReturnTypeOfRequest;
export async function requestHrpReporting(
    method: "getInstruments",
    data: GetInstrumentsProps,
): ReturnTypeOfRequest;
export async function requestHrpReporting(
    method: "hrpMarkets",
    data: HrpMarketsProps,
): ReturnTypeOfRequest;
export async function requestHrpReporting(
    method: "mapInstrument",
    data: MapInstrumentProps,
): ReturnTypeOfRequest;
export async function requestHrpReporting(
    method: "delInstrument",
    data: DelInstrumentProps,
): ReturnTypeOfRequest;
export async function requestHrpReporting(
    method: "mapClient",
    data: MapClientProps,
): ReturnTypeOfRequest;
export async function requestHrpReporting(
    method: "delClient",
    data: DelClientProps,
): ReturnTypeOfRequest;
export async function requestHrpReporting(method: string, data: object): ReturnTypeOfRequest {
    return request(method, data, Api.hrpReporting);
}
