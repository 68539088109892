import {
    ClientId,
    CounterpartyIcon,
    DropdownOption,
    getMatch,
    getMatchStartsWith,
    HStack,
    P,
} from "@fm-frontend/uikit";
import { FC } from "react";
import styled from "styled-components";

const Container = styled(HStack)`
    align-items: center;
    gap: 6px;
    overflow: hidden;
`;

type OptionEssenceProps = {
    option: DropdownOption<number | string> | undefined;
    query?: string;
    groupingOption?: boolean;
};

export const OptionEssence: FC<OptionEssenceProps> = ({ option, groupingOption, query = "" }) => {
    if (!option) {
        return null;
    }

    return (
        <Container>
            {!groupingOption && <CounterpartyIcon name={option.text} size={20} />}
            <P ellipsis>{getMatch(option.text, query) ?? option.text}</P>
            {!groupingOption && (
                <ClientId
                    id={getMatchStartsWith(String(option.value), query) ?? option.value}
                    tooltip={null}
                />
            )}
        </Container>
    );
};
