import { Listbox } from "@headlessui/react";
import React, { useMemo } from "react";
import { BasicOptionEssence } from "./BasicOptionEssence";
import { MultipleDropdownSheet, SingleDropdownSheet } from "./BasicSheet";
import { BasicTriggerEssence } from "./BasicTriggerEssence";
import { Divider } from "./Divider";
import { EmptySearchFn } from "./EmptySearch";
import { MultipleOptionFn, OptionFn } from "./Option";
import {
    Popover as DropdownPopover,
    PopoverAlign as DropdownPopoverAlign,
    PopoverPosition as DropdownPopoverPosition,
} from "./Popover";
import { SearchFn } from "./Search";
import { SheetFn, SheetGroupFn } from "./Sheet";
import { SheetInfoBox } from "./SheetInfoBox";
import { TriggerFn, TriggerRenderProps } from "./Trigger";
import { DropdownOption } from "./types";

type CommonDropdownProps<T> = {
    positions?: DropdownPopoverPosition[];
    align?: DropdownPopoverAlign;
    options: DropdownOption<T>[];
    asFilter?: boolean;
    children: React.ReactElement;
} & TriggerRenderProps;

type DropdownFnProps<T> = {
    value?: T;
    defaultValue?: T;
    onChange: (value: T) => void;
    renderTrigger: (
        props: {
            active: boolean;
            selectedValue: T;
            selectedOption?: DropdownOption<T>;
            options: DropdownOption<T>[];
            filterActive?: boolean;
        } & TriggerRenderProps,
    ) => React.ReactElement;
} & CommonDropdownProps<T>;

type MultipleDropdownFnProps<T> = {
    values?: T[];
    defaultValues?: T[];
    onChange: (values: T[]) => void;
    renderTrigger: (
        props: {
            active: boolean;
            selectedValues: T[];
            selectedOptions: DropdownOption<T>[];
            options: DropdownOption<T>[];
            filterActive?: boolean;
        } & TriggerRenderProps,
    ) => React.ReactElement;
} & CommonDropdownProps<T>;

const DropdownFn = <T,>({
    value,
    defaultValue,
    onChange,
    renderTrigger,
    children,
    options,
    asFilter,
    // popover props
    positions = ["bottom"],
    align = "start",

    //trigger render props
    placeholder,
    disabled,
    error,
    hint,
    caption,
    fullWidth,
}: DropdownFnProps<T>) => {
    const optionMap = useMemo(() => {
        return new Map(options.map((opt) => [opt.value, opt]));
    }, [options]);

    return (
        <Listbox
            defaultValue={defaultValue}
            value={value ?? null}
            onChange={onChange}
            disabled={disabled}
        >
            {({ open }) => (
                <DropdownPopover
                    padding={8}
                    positions={positions}
                    align={align}
                    isOpen={open}
                    content={children}
                >
                    <Listbox.Button as={React.Fragment}>
                        {({ value, open, disabled }) =>
                            renderTrigger({
                                selectedValue: value,
                                selectedOption: optionMap.get(value),
                                active: open,
                                disabled,
                                placeholder,
                                error,
                                hint,
                                caption,
                                fullWidth,
                                filterActive: asFilter && Boolean(value),
                                options,
                            })
                        }
                    </Listbox.Button>
                </DropdownPopover>
            )}
        </Listbox>
    );
};

const MultipleDropdownFn = <T,>({
    values,
    defaultValues,
    onChange,
    renderTrigger,
    children,
    options,

    // popover props
    positions = ["bottom"],
    align = "start",

    //trigger render props
    placeholder,
    disabled,
    error,
    hint,
    caption,
    fullWidth,
    asFilter,

    hideErrorHint = false,
}: MultipleDropdownFnProps<T>) => {
    const optionMap = useMemo(() => {
        return new Map(options.map((opt) => [opt.value, opt]));
    }, [options]);

    const getSelectedOptions = (values: T[]) => {
        return values.reduce<DropdownOption<T>[]>((acc, curr) => {
            if (optionMap.has(curr)) {
                acc.push(optionMap.get(curr)!);
            }

            return acc;
        }, []);
    };

    return (
        <Listbox
            defaultValue={defaultValues}
            value={values}
            onChange={onChange}
            multiple
            disabled={disabled}
        >
            {({ open }) => (
                <DropdownPopover
                    padding={8}
                    positions={positions}
                    align={align}
                    isOpen={open}
                    content={children}
                >
                    <Listbox.Button as={React.Fragment}>
                        {({ value, open, disabled }) =>
                            renderTrigger({
                                selectedValues: value,
                                selectedOptions: getSelectedOptions(value),
                                active: open,
                                disabled,
                                placeholder,
                                error,
                                hint,
                                caption,
                                fullWidth,
                                filterActive: Boolean(
                                    asFilter && Array.isArray(value) && value.length,
                                ),
                                options,
                                hideErrorHint,
                            })
                        }
                    </Listbox.Button>
                </DropdownPopover>
            )}
        </Listbox>
    );
};

export * from "./types";
export const OPTIONS_SEARCH_COUNT = 5;

export const SingleDropdown = Object.assign(DropdownFn, {
    Trigger: TriggerFn,
    Option: OptionFn,
    StyledOption: Option,
    Sheet: SheetFn,
    SheetGroup: SheetGroupFn,
    Search: SearchFn,
    Divider,
    TriggerEssence: BasicTriggerEssence,
    OptionEssence: BasicOptionEssence,
    EmptySearch: EmptySearchFn,
    SheetInfoBox,
    BasicSheet: SingleDropdownSheet,
});

export const MultipleDropdown = Object.assign(MultipleDropdownFn, {
    Trigger: TriggerFn,
    Option: MultipleOptionFn,
    Sheet: SheetFn,
    SheetGroup: SheetGroupFn,
    Search: SearchFn,
    Divider,
    TriggerEssence: BasicTriggerEssence,
    OptionEssence: BasicOptionEssence,
    EmptySearch: EmptySearchFn,
    SheetInfoBox,
    BasicSheet: MultipleDropdownSheet,
});
